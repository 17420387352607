import React from 'react';
import '../index.css'; 



const Index = () =>{
  return (
    <body>
    <div className='navbar'>
      <button> <a href='#adinor'>ADINOR SANTANNA</a></button>
      <button><a href='#projetos' >PROJETOS</a></button>
      <button><a href='#Sobremim'>SOBRE MIM</a></button>
      <button><a href='#contatos'>CONTATOS</a></button>
    </div>
    
    <div className='containertab' id='adinor'>
      <div className='imagem chuva'></div>
      <h2 className='adinor'>ADINOR SANT'ANNA</h2> 
      <p className='textname'>DESENVOLVEDOR WEB E DESENVOLVEDOR DE SITEMAS <br></br> REACT.JS / JAVASCRIPT / PHP / C# / MYSQL / GIT GITHUB / SQLSERVER</p>
      <div className='contatos'>
      <div id='contatos'>
      <a href='https://www.linkedin.com/in/adinorsantanna/'>LINKEDIN </a> 
      <a href='https://www.instagram.com/adinorsantanna/'>INSTAGRAM</a>
      <a href='adinor_original@hotmail.com'>adinor_original@hotmail.com</a>
      
      </div>
      <div className='imgmobile'> </div>
      </div>

    </div>

    <div>
    <div className="container" id='projetos'>
      <div className='row adinor pad1'>
        <div className='rowprojects'> PROJETOS </div> 

      </div>
      <div className="row">
        <div className="box box1"></div>
        <div className="box box2"></div>
        <div className="box box3"></div>
      </div>
      <div className='textprojeto1'>
        Projeto Realizado com o intuito de agilizar criação de formulários para equipe interna defesa civil , Feito com React.js.
      </div>
      <div className="row">
        <div className="box box4"></div>
        <div className="box box5"></div>
        <div className="box box6"></div>
      </div>
      <div className='textprojeto1'>
        Criação de Site em JAVASCRIPT. Usei uma Api do google Maps para localizar o cartório.
      </div>
      <div className="row">
        <div className="box box7"></div>
        <div className="box box8"></div>
        <div className="box box9"></div>
      </div>
      <div className='textprojeto1'>
      Criação de Site em JAVASCRIPT. Usei uma Api do google Maps para localizar o cartório.
      </div>
      <div className="row">
        <div className="box box10"></div>
        <div className="box box11"></div>
        <div className="box box12"></div>
      </div>
      <div className='textprojeto1'>
      Criaçao de Gerador de Senha, de 6 à 20 caracteres com salvamento automático e gerenciamento do próprio usuário, feito em REACT NATIVE (expo).
      </div>
      <div className="row">
        <div className="box box13"></div>
        <div className="box box14"></div>
        <div className="box box15"></div>
      </div>
      <div className='textprojeto1'>
      Criação e Manipulação de Mapa com manuseio da biblioteca LEAFLET e LEAFLET AWESOME ICONS.
      </div>
    </div>

    <div id='Sobremim'>
      <div>
      <h1 className='eu'>Sobre Mim</h1>
      <p className='text'>
      SOU ADINOR SANTANNA, FILHO DE JOANA DARC SANTANNA E ADINOR ANTONIO DE CARVALHO, ATUALMENTE RESIDENTE EM PETRÓPOLIS. <br></br>
      COM MEUS 25 ANOS DE IDADE, ENCONTRO NA FASCINANTE INTERSEÇÃO ENTRE TECNOLOGIA E INOVAÇÃO O MEU CAMPO DE PAIXÃO E DEDICAÇÃO. <br></br>
      ALÉM DE MINHA ATUAÇÃO PROFISSIONAL NA ÁREA DE TECNOLOGIA DA INFORMAÇÃO NA DEFESA CIVIL, SOU UM ENTUSIASTA E PRATICANTE ASSÍDUO DE ESPORTES. <br></br>
      COMO ATLETA, DEDICO-ME AO VOLEIBOL E AO JIU-JITSU, ENCONTRANDO NA DISCIPLINA E NA SUPERAÇÃO DE DESAFIOS UMA VALIOSA FONTE DE INSPIRAÇÃO PARA MINHA VIDA COTIDIANA. <br></br>
      MEU PERCURSO TAMBÉM CONTA COM UMA MARCANTE EXPERIÊNCIA MILITAR, ONDE SERVI DURANTE SEIS ANOS NAS TROPAS, ADQUIRINDO NÃO APENAS HABILIDADES TÉCNICAS, <br></br> MAS TAMBÉM VALORES ESSENCIAIS COMO DISCIPLINA, RESILIÊNCIA E TRABALHO EM EQUIPE. ESSA BAGAGEM, SOMADA À MINHA PAIXÃO PELA TECNOLOGIA, MOLDOU MINHA ABORDAGEM PROATIVA E INOVADORA NO AMBIENTE PROFISSIONAL. <br></br>
     ESTOU COMPROMETIDO EM BUSCAR CONSTANTEMENTE O APRIMORAMENTO, TANTO NO ÂMBITO TECNOLÓGICO QUANTO NAS ÁREAS ESPORTIVAS QUE TANTO ME CATIVAM. ASSIM, ATRAVÉS DESSA JORNADA MULTIFACETADA, BUSCO CONTRIBUIR DE MANEIRA SIGNIFICATIVA NÃO APENAS PARA O MEU CRESCIMENTO PESSOAL E PROFISSIONAL, MAS TAMBÉM PARA O BEM-ESTAR E A EXCELÊNCIA NAS ATIVIDADES QUE ABRAÇO COM PAIXÃO E DETERMINAÇÃO.
      </p>
      </div>
      <div id='container'>
      <div className="box boxone"></div>
      <div className="box boxtwo"></div>
           
        </div> 
        <div className='container textimg'>Em honra aos 90 anos de uma vida repleta de amor, sabedoria e alegria, dedicamos este espaço a uma mulher extraordinária: nossa amada Vovó Arlete.

Com cada ano que passa, sua presença ilumina nossas vidas como um farol, guiando-nos com ternura e paciência. Seu sorriso é a expressão viva de histórias encantadoras, de lições preciosas e de uma jornada incrível que moldou a pessoa incrível que é.

Que este pequeno canto virtual seja um tributo modesto à sua sabedoria infindável, ao seu afeto incondicional e à beleza de seus 90 anos. Que cada pixel destas palavras reflita o brilho da sua alma e o calor do seu abraço, celebrando uma vida tão rica e inspiradora.

</div> 
     

    </div>
    </div>

  <footer>
    <p>@ADINOR SANTANNA</p>
  </footer>


    </body>
  );
}

export default Index;