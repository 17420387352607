import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import Index from "../pages";
import Projetos from "../pages/projetos";
import Sobremim from "../pages/sobremim";
import Contatos from "../pages/contatos";

const RoutesComponent = () => {
    return (
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Index />} />
          <Route path="/projetos" element={<Projetos />} />
          <Route path="/sobremim" element={<Sobremim />} />
          <Route path="/contatos" element={<Contatos />} />
        </Routes>
      </BrowserRouter>
    );
  };
  
  export default RoutesComponent;