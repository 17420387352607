import React from 'react';
import './App.css';
import RoutesComponent from '../src/components/RoutesComponent'




export default function App() {
   return (
       <RoutesComponent/>
   );
}